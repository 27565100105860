import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import EmailIcon from '@mui/icons-material/Email'
import PlaceIcon from '@mui/icons-material/Place'
import CallIcon from '@mui/icons-material/Call'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import './styles.css'

import { toast } from 'react-toastify'

function ContactUs() {
	const recaptchaRef = useRef()
	const form = useRef()

	const sendEmail = (e) => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_2xdz2xb',
				'template_sjd6qqp',
				form.current,
				'LjB4-Tvh2obj5eUKs'
			)
			.then(
				(result) => {
					// console.log(result.text)
					e.target.reset()
					toast.success('Mesajınız başarıyla gönderildi.')
				},
				(error) => {
					console.log(error.text)
					toast.error('Mesajınız gönderilemedi. Daha sonra tekrar deneyin.')
				}
			)
	}

	const onChange = (value) => {
		console.log('Captcha value:', value)
	}

	const onSubmit = () => {
		const recaptchaValue = recaptchaRef.current.getValue()
		console.log(recaptchaValue)
	}

	return (
		<div id='bize-ulasin' className='w-full flex flex-col justify-center'>
			<div className=''>
				<div className='z-10 relative flex justify-center'>
					<div className=' max-w-[1280px] w-full max-sm:w-full flex flex-col justify-center'>
						<div className='flex flex-col justify-center items-center m-5'>
							<h1 className='font-bold text-[48px] text-center'>Bize Ulaşın</h1>
							<p className='text-center'>
								Faaliyetlerimizle ilgili her türlü soruyu bize sormaktan
								çekinmeyin. Size cevap vermekten mutluluk duyarız.
							</p>
							<div className='flex'>
								<EmailIcon />
								<span>info@rastmakine.com</span>
							</div>
						</div>
						<div className='flex flex-wrap justify-evenly items-center '>
							<div className='m-2 max-w-[1280px] lg:w-full h-96'>
								<iframe
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.7954502606403!2d32.51673921599892!3d37.93520881068455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d0858dcb1b35dd%3A0xe81913c54efee749!2sRast%20Makine!5e0!3m2!1str!2str!4v1680883364007!5m2!1str!2str'
									style={{
										border: '0px',
										borderRadius: '10px',
										overflow: 'hidden',
										marginTop: '10px',
										width: '100%',
										height: '100%',
									}}
									loading='lazy'></iframe>
							</div>
							<div className='flex justify-evenly flex-wrap items-end  w-full'>
								<div>
									<h1 className='font-bold text-[36px] text-center my-10'>
										İletişim Formu
									</h1>
									<form
										ref={form}
										onSubmit={sendEmail}
										className='text-white flex flex-col justify-evenly items-center'>
										<div className='w-[400px] h-full flex flex-col justify-start items-center'>
											<TextField
												id='outlined-basic'
												name='user_name'
												label='İsminiz'
												sx={{
													width: '100%',
													margin: '5px',
													borderRadius: '5px',
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#fff',
														},
														'&:hover fieldset': {
															borderColor: '#fff',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#fff',
														},
													},
												}}
												InputLabelProps={{
													style: { color: '#fff' },
												}}
											/>

											<TextField
												id='outlined-basic'
												name='user_email'
												label='E-posta'
												sx={{
													width: '100%',
													margin: '5px',
													borderRadius: '5px',
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#fff',
														},
														'&:hover fieldset': {
															borderColor: '#fff',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#fff',
														},
													},
												}}
												InputLabelProps={{
													style: { color: '#fff' },
												}}
											/>
										</div>
										<div className='w-[400px] h-full flex flex-col justify-start items-center'>
											<TextField
												id='outlined-basic'
												label='Konu'
												name='subject'
												sx={{
													width: '100%',
													margin: '5px',
													borderRadius: '5px',
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#fff',
														},
														'&:hover fieldset': {
															borderColor: '#fff',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#fff',
														},
													},
												}}
												InputLabelProps={{
													style: { color: '#fff' },
												}}
											/>
											<TextField
												id='outlined-multiline-static'
												name='message'
												label='Mesajınız'
												multiline
												rows={4}
												maxRows={4}
												sx={{
													width: '100%',
													margin: '5px',
													borderRadius: '5px',
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#fff',
														},
														'&:hover fieldset': {
															borderColor: '#fff',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#fff',
														},
													},
												}}
												InputLabelProps={{
													style: { color: '#fff' },
												}}
											/>

											<button className='w-full flex justify-center items-center px-4 m-2 font-bold text-black bg-[#01b0f1] rounded-md transition ease-in-out delay-150 hover:scale-110 duration-300 cursor-pointer'>
												<h4 className='p-2 '>Gönder</h4>
											</button>
										</div>
									</form>
								</div>
								<div className='w-[400px] h-[400px]'>
									<div className='h-[300px] flex justify-center items-center border-4 rounded-md p-5 m-2'>
										<ul className='h-full flex flex-col justify-evenly'>
											<li className='flex justify-between items-center p-3'>
												<PlaceIcon />
												<span>Horozluhan Mah. Hırka Sok. No: 11</span>
											</li>
											<li className='flex justify-between items-center p-3'>
												<CallIcon />
												<span>+90 532 390 72 78</span>
											</li>
											<li className='flex justify-between items-center p-3'>
												<EmailIcon />
												<span>info@rastmakine.com</span>
											</li>
										</ul>
									</div>
									<div className='h-[100px] border-4 rounded-md p-5 m-2'>
										<ul className='h-full flex justify-evenly items-center '>
											<li className='flex justify-center items-center '>
												<IconButton>
													<a
														href='https://www.facebook.com/rastmakine'
														target='blank'>
														<FacebookIcon style={{ color: '#fff' }} />
													</a>
												</IconButton>
											</li>
											<li className='flex justify-center items-center'>
												<IconButton>
													<a
														href='https://api.whatsapp.com/message/VHRPXS2Z7EMBM1?autoload=1&app_absent=0'
														target='blank'>
														<WhatsAppIcon style={{ color: '#fff' }} />
													</a>
												</IconButton>
											</li>
											<li className='flex justify-center items-center'>
												<IconButton>
													<a
														href='https://www.youtube.com/channel/UCxG_Xwy9ZMYJHNpXULBXW2Q'
														target='blank'>
														<YouTubeIcon style={{ color: '#fff' }} />
													</a>
												</IconButton>
											</li>
											<li className='flex justify-center items-center'>
												<IconButton>
													<a
														href='https://www.instagram.com/rastmakine/'
														target='blank'>
														<InstagramIcon style={{ color: '#fff' }} />
													</a>
												</IconButton>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactUs
