import React from 'react'
import { useEffect, useRef, useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import {
	Bolt,
	Tune,
	CreateNewFolder,
	Science,
	InvertColors,
	Done,
} from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Slide, Fade } from '@mui/material'
import HizliDolumSM from '../../assets/images/sm-hizli-dolum.png'
import NetDolumSM from '../../assets/images/sm-net-dolum.png'
import SM1 from '../../assets/images/sm-w-1.jpeg'
import SM2 from '../../assets/images/sm-w-2.jpeg'
import SM3 from '../../assets/images/sm-w-3.jpeg'
import SM4 from '../../assets/images/sm-w-4.jpeg'
import SM5 from '../../assets/images/sm-w-5.jpeg'
import SM6 from '../../assets/images/sm-w-6.jpeg'
function SormMaster() {
	const theme = useTheme()

	const data = [
		{
			icon: Bolt,
			text: 'Hızlı ve net ölçülerde dolum',
			iconColor: '#000',
			dotColor: '#fff',
		},
		{
			icon: Tune,
			text: '4 farklı hacim birimi seçimi (mililitre, litre, oz ve galon)',
			iconColor: '#000',
			dotColor: '#fff',
		},
		{
			icon: CreateNewFolder,
			text: '10 farklı reçete kaydedebilme, değiştirme ve silme özelliği',
			iconColor: '#000',
			dotColor: '#fff',
		},
		{
			icon: Science,
			text: 'Gıda, yağ veya kimyasallara uygun',
			iconColor: '#000',
			dotColor: '#fff',
		},
		{
			icon: InvertColors,
			text: 'AISI 304/316 Paslanmaz Çelik',
			iconColor: '#000',
			dotColor: '#fff',
		},
	]

	const specifications = [
		{ text: 'Uzunluk x Genişlik x Yükseklik: 500 x 500 x 1100 mm' },
		{ text: 'Maksimum debi: 45 L/dk ~ 3m³/saat' },
		{ text: '220VAC/380VAC Seçenekleri' },
		{ text: 'Sıvıya temas eden tüm yüzeyler AISI 304 paslanmaz' },
		{ text: 'Gıdaya ve yağa uygun hortum' },
		{ text: 'Paslanmaz kamlok bağlantı' },
		{ text: 'Kendinden emiş sistemi' },
		{ text: 'Nozul boyu değiştirilebilen özel tasarım dolum tabancası' },
		{ text: 'Renkli TFT LCD ekran' },
		{ text: 'Sesli ve ışıklı hata bildirimleri' },
	]

	const [currentImage, setCurrentImage] = useState(0)
	const [currentImage2, setCurrentImage2] = useState(0)
	const images = [SM1, SM2, SM3, SM4, SM5, SM6]
	const images2 = [HizliDolumSM, NetDolumSM]

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage((prevImage) => (prevImage + 1) % images.length)
		}, 3000) // Change image every 3 seconds
		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage2((prevImage) => (prevImage + 1) % images2.length)
		}, 3000) // Change image every 3 seconds
		return () => clearInterval(interval)
	}, [])

	return (
		<div className='w-full flex flex-col justify-center items-center '>
			<div className='flex flex-col justify-center items-center  max-w-[1280px]  mt-8'>
				<img src={images2[currentImage2]} alt='Sorm Master' />
			</div>
			<div className='max-w-[1280px] max-sm:hidden'>
				<Timeline
					position='alternate'
					sx={{
						marginTop: '2rem',
						marginBottom: '2rem',
					}}>
					{data.map((el, index) => (
						<Slide
							direction={index % 2 === 0 ? 'right' : 'left'}
							in={true}
							timeout={index * 600}
							mountOnEnter
							unmountOnExit>
							<TimelineItem key={index}>
								<TimelineOppositeContent
									sx={{ m: 'auto 0' }}
									align='right'
									variant='body2'
									color='text.secondary'>
									<Typography
										variant='h6'
										style={{
											color: 'white',
											fontWeight: 'bold',
											fontSize: '1.5rem',
										}}>
										{el.text}
									</Typography>
								</TimelineOppositeContent>

								<TimelineSeparator>
									<TimelineDot sx={{ backgroundColor: el.dotColor }}>
										<el.icon sx={{ color: el.iconColor, fontSize: '2rem' }} />
									</TimelineDot>
									<Fade in={true} timeout={10000} mountOnEnter unmountOnExit>
										<TimelineConnector
											style={{
												width: '5px',
												backgroundColor: el.dotColor,
												borderRadius: '5px',
											}}
										/>
									</Fade>
								</TimelineSeparator>
								<TimelineContent></TimelineContent>
							</TimelineItem>
						</Slide>
					))}
				</Timeline>
			</div>
			<div className='sm:hidden'>
				<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 my-4'>
					{data.map((spec, index) => (
						<Fade in={true} timeout={index * 1500} mountOnEnter unmountOnExit>
							<li key={index} className='flex items-center gap-4'>
								<spec.icon />
								<p className=' '>{spec.text}</p>
							</li>
						</Fade>
					))}
				</ul>
			</div>
			<div className='max-w-[1280px] w-full flex flex-col justify-center items-center'>
				<Fade
					direction='down'
					in={true}
					timeout={5000}
					mountOnEnter
					unmountOnExit>
					<h1 className='text-[56px] font-bold text-center'>Sorm Master</h1>
				</Fade>
				<div className='flex justify-center items-center w-full max-w:[1280px]  mt-4'>
					{/* <video src=''></video> */}
					<iframe
						width='1903'
						height='720'
						className='max-sm:h-[243px] max-sm:w-full max-sm:object-cover max-sm:p-3'
						src='https://www.youtube.com/embed/Dbx_G4Z3ETk?autoplay=1&mute=1&loop=1&playlist=Dbx_G4Z3ETk'
						title='Sıvı Dolum Makinesi - SORM MASTER Tabancalı Dolum Makinesi / 5 Litre Dolum'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						referrerpolicy='strict-origin-when-cross-origin'
						allowfullscreen></iframe>
				</div>

				<div className='flex flex-col  justify-center items-center w-full max-w:[1280px] bg-[#f7f7f7] mt-8 pt-4'>
					<h1 className='text-[36px] font-bold text-center text-black'>
						Başlıca Özellikler
					</h1>
					<div className='flex flex-wrap justify-center items-center w-full mt-4 ]'>
						<div className='flex '>
							<img
								src={images[currentImage]}
								alt='Sorm Master'
								className='max-w-[640px] max-sm:w-full'
							/>
						</div>
						<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-4 text-black'>
							{specifications.map((spec, index) => (
								<li key={index} className='flex items-center gap-4'>
									<Done />
									<p className=' '>{spec.text}</p>
								</li>
							))}
						</ul>
					</div>
				</div>

				<div className='flex flex-col justify-center items-center mt-8 p-4'>
					<h5 className='text-center'>
						Faaliyetlerimizle ilgili her türlü soruyu bize sormaktan çekinmeyin.
						Size cevap vermekten mutluluk duyarız.
					</h5>
					<p className='text-center'>+90 532 390 72 78</p>
				</div>
			</div>
		</div>
	)
}

export default SormMaster
