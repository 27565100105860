import React from 'react'
import Tick from '../assets/images/svg/tickcircle.svg'
import Button from '@mui/material/Button'
import { ArrowRightAlt } from '@mui/icons-material'
import SormMasterPin from '../assets/images/sorm-master-pins.png'
import Slide from '@mui/material/Slide'

function SMFeatured() {
	const items = [
		'Petrol pompası konforunda sıvı dolumu',
		'4 farklı hacim birimi seçimi (mililitre, litre, oz ve galon)',
		'Hızlı ve net ölçülerde dolum',
		'AISI 304 Paslanmaz Çelik',
		'Gıda, yağ veya kimyasallara uygun',
		'10 farklı reçete kaydedebilme, değiştirme ve silme',
	]
	return (
		<div className='w-full flex justify-center items-center'>
			<div className='max-w-[1280px] flex-wrap-reverse w-full flex justify-between max-lg:justify-center items-center sm:p-24 max-sm:p-8 gap-8'>
				<div className='flex flex-col gap-8 max-lg:justify-center max-lg:items-center '>
					<h1 className='text-[72px] font-bold max-sm:text-center'>
						SORM Master
					</h1>
					<ul className='flex flex-col  gap-4'>
						{items.map((item, index) => (
							<li key={index} className='flex items-center gap-4'>
								<img src={Tick} alt='Tick' />
								<span>{item}</span>
							</li>
						))}
					</ul>
					<Button
						variant='contained'
						color='turquoise'
						endIcon={<ArrowRightAlt />}
						size='large'
						style={{ width: '200px' }}
						href='/sorm-master'>
						Detaylar
					</Button>
				</div>
				<div>
					<Slide
						direction={'down'}
						in={true}
						timeout={2000}
						mountOnEnter
						unmountOnExit>
						<img src={SormMasterPin} alt='Sorm Master' />
					</Slide>
				</div>
			</div>
		</div>
	)
}

export default SMFeatured
