import React from 'react'
import RASTLogo from '../assets/images/logos/rast-logo.png'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Menu } from '@mui/icons-material'
import { Drawer, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useLocation } from 'react-router-dom'

function Header() {
	const [menuOpen, setMenuOpen] = useState(false)
	const theme = useTheme()
	const location = useLocation()

	const menu_items = [
		{
			name: 'Sorm Master',
			link: 'sorm-master',
			toDiv: false,
		},
		{
			name: 'Sorm Basic',
			link: 'sorm-basic',
			toDiv: false,
		},
		{
			name: 'Çalışma Alanlarımız',
			link: 'calisma-alanlarimiz',
			toDiv: true,
		},
		{
			name: 'Hakkımızda',
			link: 'hakkimizda',
			toDiv: true,
		},
		{
			name: 'Bize Ulaşın',
			link: 'bize-ulasin',
			toDiv: true,
		},
	]

	const toggleMenu = () => {
		setMenuOpen(!menuOpen)
	}
	return (
		<>
			<header
				className={`flex w-full max-sm:justify-between max-sm:items-center max-sm:p-4 h-24 py-6 px-20 z-10 
					${location.pathname === '/sorm-basic' ? 'absolute top-0' : ''}
				`}>
				<div className='flex w-1/2 '>
					<a className='font-bold text-[32px]' href='/'>
						<img src={RASTLogo} alt='RAST Logo' className='h-12' />
					</a>
				</div>
				<div className='flex w-1/2 max-2xl:hidden'>
					<div className='flex w-full justify-start items-center'>
						<ul className='flex gap-4 h-12 justify-center items-center'>
							{menu_items.map((item, index) => (
								<li
									className='h-full flex justify-center items-center'
									key={index}>
									<a
										href={
											location.pathname === '/' && item.toDiv
												? `#${item.link}`
												: item.toDiv
												? `/#${item.link}`
												: `/${item.link}`
										}
										className={`h-full flex justify-center items-center font-medium cursor-pointer px-3 py-2 `}>
										{item.name}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className='2xl:hidden sm:w-1/2 flex justify-end items-center z-10'>
					<div className='max-sm:block cursor-pointer' onClick={toggleMenu}>
						<IconButton onClick={toggleMenu}>
							<Menu style={{ color: theme.palette.text.primary }} />
						</IconButton>
					</div>
				</div>
			</header>
			<Drawer
				variant={'temporary'}
				anchor={'right'}
				sx={{
					'& .MuiDrawer-paper': {
						width: '25%',
						boxSizing: 'border-box',
						border: 'none',
						backgroundColor: '#002060E2',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						// [theme.breakpoints.between('md', 'xl')]: {
						//   display: 'none',
						// },
						[theme.breakpoints.down('md')]: {
							width: '100%',
						},
					},
				}}
				open={menuOpen}
				onClose={toggleMenu}
				onOpen={toggleMenu}>
				<div className='flex flex-col h-full justify-center items-center gap-8'>
					<div className='w-full p-2 m-2 flex justify-start items-center'>
						<IconButton onClick={toggleMenu}>
							<ChevronRightIcon style={{ color: theme.palette.text.primary }} />
						</IconButton>
					</div>
					<ul className='w-full h-full flex flex-col gap-2 m-2 p-2'>
						{menu_items.map((item, index) => (
							<li
								className='w-full flex justify-center items-center'
								key={index}>
								<a
									href={
										location.pathname === '/' && item.toDiv
											? `#${item.link}`
											: item.toDiv
											? `/#${item.link}`
											: `/${item.link}`
									}
									className={`h-full flex justify-center items-center font-medium cursor-pointer px-3 py-2 `}>
									{item.name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</Drawer>
		</>
	)
}

export default Header
