import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Inter',
    },
    palette: {
        background: {
            default: "#002060",
        },
        text: {
            primary: "#fff",
            secondary: "#000",
        },
        primary: {
            main: "#fff",
            secondary: "#f2f2f2",
            tertiary: "#e6e6e6",
        },
        dark: {
            main: "#161b22",
            secondary: "#1f2d3d",
            tertiary: "#2d3e50",
        },
        navy: {
            main: "#002060",
            secondary: "#003080",
            tertiary: "#0040a0",
        },
        orange: {
            main: "#ffc000",
            secondary: "#ffcc00",
            tertiary: "#ffd633",
        },
        turquoise: {
            main: "#01b0f1",
            secondary: "#00c4ff",
            tertiary: "#00d8ff",
        },
        green: {
            main: "#006020",
            secondary: "#007020",
            tertiary: "#008020",
        }
    },
});
