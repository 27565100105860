import React from 'react';
import Home from '../pages/Home';
import SormBasic from '../pages/SormBasic';
import SormMaster from '../pages/SormMaster/SormMaster';
import Qr from '../pages/QR';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/sorm-basic',
    element: <SormBasic />,
  },
  {
    path: '/sorm-master',
    element: <SormMaster />,
  },
  {
    path: '/mustafa-koyuncu',
    element: <Qr />,
  },

];

export default routes;
